import at from 'svg-country-flags/svg/at.svg';
import cz from 'svg-country-flags/svg/cz.svg';
import de from 'svg-country-flags/svg/de.svg';
import no from 'svg-country-flags/svg/no.svg';
import pl from 'svg-country-flags/svg/pl.svg';
import si from 'svg-country-flags/svg/si.svg';
import sk from 'svg-country-flags/svg/sk.svg';
import jp from 'svg-country-flags/svg/jp.svg';
import ca from 'svg-country-flags/svg/ca.svg';
import us from 'svg-country-flags/svg/us.svg';
import fi from 'svg-country-flags/svg/fi.svg';
import ru from 'svg-country-flags/svg/ru.svg';
import ro from 'svg-country-flags/svg/ro.svg';
import ua from 'svg-country-flags/svg/ua.svg';
import tr from 'svg-country-flags/svg/tr.svg';
import cn from 'svg-country-flags/svg/cn.svg';
import kz from 'svg-country-flags/svg/kz.svg';
import ee from 'svg-country-flags/svg/ee.svg';
import it from 'svg-country-flags/svg/it.svg';
import ch from 'svg-country-flags/svg/ch.svg';
import fr from 'svg-country-flags/svg/fr.svg';
import bg from 'svg-country-flags/svg/bg.svg';
import kr from 'svg-country-flags/svg/kr.svg';
import se from 'svg-country-flags/svg/se.svg';

const flags = {
    AUT: at,
    BUL: bg,
    CAN: ca,
    SUI: ch,
    CHN: cn,
    CZE: cz,
    GER: de,
    EST: ee,
    FIN: fi,
    FRA: fr,
    ITA: it,
    KAZ: kz,
    KOR: kr,
    JPN: jp,
    NOR: no,
    POL: pl,
    ROU: ro,
    RUS: ru,
    SLO: si,
    SWE: se,
    SVK: sk,
    TUR: tr,
    UKR: ua,
    USA: us,
};

export default flags;