import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { makeStyles } from "@mui/styles";
import { logout } from "../pages/login/authSlice";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    color: "#fff",
    // fontSize: '24px',
    // "@media (max-width:780px)": {
      // eslint-disable-line no-useless-computed-key
      // fontSize: 18,
    // },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const logout = async () => {
    await props.logout();
    navigate("/");
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {/* <MenuIcon onClick={toggleDrawer}/> */}
          </IconButton>
          <a href="/" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/en/thumb/0/07/F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg/1200px-F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg.png"
              width="32"
              height="32"
              style={{ marginRight: 20 }}
            />

            <Typography variant="h5" className={classes.title}>
              Virtual Judge
            </Typography>
          </a>

          <Box style={{ display: "flex", marginLeft: 32 }}>
            <Button href="/competitions" style={{ my: 2, color: "white" }}>
              Competitions
            </Button>
            <Button href="/marks" style={{ my: 2, color: "white" }}>
              Marks
            </Button>
          </Box>
        </Box>
        <Button color="inherit">Logged as {props.username}</Button>
        <Button color="inherit" onClick={logout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  isAuthUser: state.auth.isAuthUser,
  user: state.auth.data,
});

export default connect(mapStateToProps, { logout })(Header);
