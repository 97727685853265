import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

export const competitionsSlice = createSlice({
  name: "competitions",
  initialState: {
    isLoading: false,
    hasError: undefined,
    data: [],
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  fetchSuccess,
  startLoading,
  hasError,
} = competitionsSlice.actions;

export const fetchCompetitions = ({ query, page } = { page: 1 }) => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get("/api/competitions/all.json", { params: { query, page }, headers })
    .then((response) => {
      dispatch(fetchSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export default competitionsSlice.reducer;
