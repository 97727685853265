import React from "react";
import { makeStyles } from "@mui/styles";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PieChartComponent from "./PieChart";
import Skeleton from "@mui/material/Skeleton";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import Box from "@mui/material/Box";

import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import flags from "../../utils/flags";

import {
  fetchLatestCompetitions,
  fetchUserProfile,
  fetchRanking,
} from "./dashboardSlice";
import { connect } from "react-redux";

const mapState = (state) => ({
  competitions: state.dashboard.latestCompetitions,
  profile: state.dashboard.profile,
  roles: state.auth.data.roles,
});

const actionCreators = {
  fetchLatestCompetitions,
  fetchUserProfile,
  fetchRanking,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  item: {
    padding: 16,
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  icon: {
    fontSize: 16,
  },
  description: {
    marginLeft: 8,
    fontWeight: 600,
  },
}));

export function Dashboard({
  fetchLatestCompetitions,
  fetchUserProfile,
  competitions,
  profile,
  roles,
}) {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    fetchLatestCompetitions();
    fetchUserProfile();
    // fetchRanking();
  }, []);

  const displayIcon = (user_mark, experts_mark) => {
    const diff = Math.abs(user_mark - experts_mark);
    if (diff <= 0.5) {
      return (
        <>
          <ThumbUpIcon className={classes.icon} style={{ color: "#4caf50" }} />
          <span className={classes.description} style={{ color: "#4caf50" }}>
            Great!
          </span>
        </>
      );
    } else if (diff > 0.5 && diff <= 1.0) {
      return (
        <>
          <ThumbUpIcon className={classes.icon} style={{ color: "#ff9800" }} />
          <span className={classes.description} style={{ color: "#ff9800" }}>
            Middling
          </span>
        </>
      );
    } else {
      return (
        <>
          <ThumbDownIcon
            className={classes.icon}
            style={{ color: "#f44336" }}
          />
          <span className={classes.description} style={{ color: "#f44336" }}>
            Poorly
          </span>
        </>
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    fetchLatestCompetitions({ page: newPage + 1 }).then((data) => {
      setPage(newPage);
    });
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Typography color="textPrimary">Home</Typography>
      </Breadcrumbs>

      {profile.competitions_not_judged && profile.competitions_not_judged > 0 && (
        <Alert severity="warning" style={{ marginTop: 24, marginBottom: 24 }}>
          You have <Chip size="small" label={profile.competitions_not_judged} />{" "}
          competitions to add your marks
        </Alert>
      )}

      <Grid container spacing={6} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid key={1} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    <FmdGoodIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.competitions_total}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>Competitions</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={2} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    {" "}
                    <EqualizerIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.avg_mark}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>My average mark</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={3} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    <ScoreboardIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.marks_total}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>Added marks</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={6} style={{ marginTop: 0 }}>
        <Grid item xs={6}>
          <h3>Your marks vs. real marks</h3>
          <Box sx={{ padding: 8, display: 'flex', justifyContent: 'center' }}>
            <PieChartComponent marks={profile.real_marks} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <h3>Your marks vs. SU marks</h3>
          <Box sx={{ padding: 8, display: 'flex', justifyContent: 'center' }}>
            <PieChartComponent marks={profile.su_marks} />
          </Box>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid key={1} xs={12} item>
              <h3>Competitions you judged</h3>
              <TableContainer component={Paper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Venue</TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">My avg</TableCell>
                      <TableCell align="center">SU avg</TableCell>
                      <TableCell align="center">RM avg</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {competitions.competitions.map((el) => (
                      <TableRow key={el.competition.id}>
                        <TableCell size="small">
                          {el.competition.date}
                        </TableCell>
                        <TableCell align="center">
                          {el.competition.hill && (
                            <Avatar src={el.competition.hill.image} />
                          )}
                        </TableCell>
                        <TableCell size="small">
                          {el.competition.hill
                            ? el.competition.hill.location.name
                            : el.competition.remarks}
                        </TableCell>
                        <TableCell align="center">
                          {el.competition.hill && (
                            <img
                              src={
                                flags[
                                  el.competition.hill.location.nation.shortname
                                ]
                              }
                              width="24"
                              height="24"
                              alt=""
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            size="small"
                            label={el.user_mark_avg.toFixed(2)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            size="small"
                            label={el.experts_mark_avg.toFixed(2)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Chip
                            size="small"
                            label={el.real_mark_avg.toFixed(2)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          {displayIcon(el.user_mark_avg, el.experts_mark_avg)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {competitions.total_entries && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={competitions.total_entries}
                  rowsPerPage={4}
                  page={page}
                  onPageChange={handleChangePage}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default connect(mapState, actionCreators)(Dashboard);
