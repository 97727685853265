import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

export const competitionsSlice = createSlice({
  name: "competition",
  initialState: {
    isLoading: false,
    hasError: undefined,
    data: null,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    fetchSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    fetchRoundsSuccess: (state, action) => {
      state.rounds = action.payload;
      state.isLoading = false;
    },
    fetchMarksSuccess: (state, action) => {
      const marks = action.payload.reduce((acc, cur) => {
        acc[cur.result_id] = cur;
        return acc;
      }, {});
      state.marks = marks;
      state.isLoading = false;
    },
    fetchJumpersSuccess: (state, action) => {
      const jumpers = action.payload.reduce((acc, cur) => {
        acc[cur.fiscodex] = cur;
        return acc;
      }, {});
      state.jumpers = jumpers;
    },
  },
});

export const {
  fetchSuccess,
  fetchJumpersSuccess,
  fetchRoundsSuccess,
  fetchMarksSuccess,
  startLoading,
  hasError,
} = competitionsSlice.actions;

export const fetchCompetition = (competitionId, round) => async (
  dispatch,
  getState
) => {
  dispatch(startLoading());
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/competitions/${competitionId}.json`, {
      params: { round },
      headers,
    })
    .then((response) => {
      dispatch(fetchSuccess(response.data));
      const fiscodex = response.data.results.map((row) => row.fiscodex);
      dispatch(fetchJumpers(fiscodex));
      dispatch(fetchMarks(competitionId));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchMarks = (competitionId) => async (dispatch, getState) => {
  dispatch(startLoading());
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/competitions/${competitionId}/marks.json`, { headers })
    .then((response) => {
      dispatch(fetchMarksSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchJumpers = (fiscodex) => async (dispatch, getState) => {
  dispatch(startLoading());
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/berkutschi/api/jumpers.json`, { params: { fiscodex }, headers })
    .then((response) => {
      dispatch(fetchJumpersSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchRounds = (competitionId) => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  return axios
    .get(`/api/competitions/${competitionId}/rounds.json`, { headers })
    .then((response) => {
      dispatch(fetchRoundsSuccess(response.data));
      return response.data;
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

// X-TdUser-Token: mM6UhB4pf4HqC7NrnBoF
// X-TdUser-Email: krzysztofbabula@gmail.com

export const saveMark = (competitionId, params) => async (
  dispatch,
  getState
) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  return axios
    .post(`/api/competitions/${competitionId}/marks.json`, params, { headers })
    .then((response) => {
      dispatch(fetchMarks(competitionId));
      return response;
    })
    .catch(function (e) {
      // console.log(e);
    });
};

export const updateMark = (competitionId, params) => async (
  dispatch,
  getState
) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  return axios
    .patch(`/api/competitions/${competitionId}/marks/${params.id}.json`, params, { headers })
    .then((response) => {
      dispatch(fetchMarks(competitionId));
      return response;
    })
    .catch(function (e) {
      // console.log(e);
    });
};

export default competitionsSlice.reducer;
