import React, { useState } from "react";
import "./App.css";
import Login from "./pages/login/Login";
import AuthRoute from "./pages/login/AuthRoute";
import Competitions from "./pages/competitions/Competitions";
import Competition from "./pages/competition/Competition";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardAdmin from "./pages/dashboard/DashboardAdmin";
import Marks from "./pages/marks/Marks";
import Header from "./components/Header";
import { logout } from "./pages/login/authSlice";

import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";
import { connect } from "react-redux";

const App = ({ isAuthUser, user, logout }) => {
  const [isOpen, toggle] = useState(false);

  const handleToggle = () => {
    toggle(!isOpen);
  };

  return (
    <Router>
      <div>
        {/* <Menu isOpen={isOpen} onToggleClick={handleToggle}/> */}
        <Routes>
          <Route exact path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/login" element={<Login />} />

          <Route element={<AuthRoute user={user} />}>
            <Route
              path="/dashboard"
              element={
                <>
                  <Header
                    onToggleClick={handleToggle}
                    username={isAuthUser && user.username}
                  />
                  {isAuthUser && user.roles.find((r) => r.name === "admin") ? (
                    <DashboardAdmin />
                  ) : (
                    <Dashboard />
                  )}
                </>
              }
            />
            <Route
              path="/competitions"
              element={
                <>
                  <Header
                    onToggleClick={handleToggle}
                    username={isAuthUser && user.username}
                  />
                  <Competitions />
                </>
              }
            />
            <Route
              path="/competition/:id"
              element={
                <>
                  <Header
                    onToggleClick={handleToggle}
                    username={isAuthUser && user.username}
                  />
                  <Competition />
                </>
              }
            />
          </Route>
          <Route
              path="/marks"
              element={
                <>
                  <Header
                    onToggleClick={handleToggle}
                    username={isAuthUser && user.username}
                  />
                  <Marks />
                </>
              }
            />
          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </div>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  isAuthUser: state.auth.isAuthUser,
  user: state.auth.data,
});

export default connect(mapStateToProps, { logout })(App);
