import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FlightIcon from "@mui/icons-material/Flight";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import GetApp from "@mui/icons-material/GetApp";
import PanTool from "@mui/icons-material/PanTool";
import InputLabel from "@mui/material/InputLabel";

import classes from "./Competition.module.css";

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
//   deduction: {
//     backgroundColor: "#4caf50",
//     color: "#fff",
//     "&:hover": {
//       backgroundColor: "#4caf50",
//     },
//   },
//   deduction2: {
//     backgroundColor: "#999",
//     "&:hover": {
//       backgroundColor: "#999",
//     },
//   },
// });

function Form(props) {
  // const classes = useStyles();

  const handleFlight = (value) => {
    // max 5.0
    const total = props.flightMark + value;
    if (total <= 0 && total >= -5) {
      props.handleMark("flight", total);
    } else {
      console.log("Error");
    }
  };

  const handleLanding = (value) => {
    // max 5.0
    const total = props.landingMark + value;
    if (total <= 0 && total >= -5) {
      props.handleMark("landing", total);
    } else {
      console.log("Error");
    }
  };

  const handleOutrun = (value) => {
    // max 7.0
    const total = props.outrunMark + value;
    if (total <= 0 && total >= -7) {
      props.handleMark("outrun", total);
    } else {
      console.log("Error");
    }
  };

  const value = 20.0 + props.flightMark + props.landingMark + props.outrunMark;

  return (
    <div id="alert-dialog-description">
      <Box display="flex" justifyContent="center" sx={{ padding: 2 }}>
        <TextField
          id="outlined-basic"
          label="Your mark"
          variant="outlined"
          value={value.toFixed(1)}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          disabled
        />
      </Box>

      <Box style={{ marginTop: 16 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <FlightIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                label="Flight"
                variant="outlined"
                value={props.flightMark.toFixed(1)}
                size="small"
                disabled
                fullWidth
                key={props.flightMark}
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <FlightLandIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
              label="Landing"
                variant="outlined"
                value={props.landingMark.toFixed(1)}
                size="small"
                disabled
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <AirportShuttleIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
              <TextField
              label="Outrun"
                variant="outlined"
                value={props.outrunMark.toFixed(1)}
                size="small"
                disabled
                inputProps={{ min: 0, style: { textAlign: "center" } }}
              />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              // color="primary"
              className={classes.deduction}
              onClick={() => handleFlight(-0.5)}
              color="success"
            >
              +0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction}
              onClick={() => handleLanding(-0.5)}
              color="success"
            >
              +0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction}
              onClick={() => handleOutrun(-0.5)}
              color="success"
            >
              +0.5
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="error"
              onClick={() => handleFlight(0.5)}
            >
              -0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="error"
              onClick={() => handleLanding(0.5)}
            >
              -0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="error"
              onClick={() => handleOutrun(0.5)}
            >
              -0.5
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleFlight(-1.0)}
            >
              +1.0
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleLanding(-1.0)}
            >
              +1.0
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-1.0)}
            >
              +1.0
            </Button>
          </Grid>

          <Grid item xs={6}>
            <InputLabel>
              Touching ground
            </InputLabel>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-5.0)}
              startIcon={<PanTool />}
            >
              -5.0
            </Button>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Fall</InputLabel>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-7.0)}
              startIcon={<GetApp />}
            >
              -7.0
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

Form.propTypes = {};

export default Form;
