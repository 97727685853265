import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import WcIcon from "@mui/icons-material/Wc";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";

import Link from "@mui/material/Link";
import flags from "../../utils/flags";

import { fetchCompetitions } from "./competitionsSlice";
import { connect } from "react-redux";

const mapState = (state) => ({
  competitions: state.competitions.data.competitions,
  per_page: state.competitions.data.per_page,
  total_entries: state.competitions.data.total_entries,
  page: state.competitions.data.current_page,
  roles: state.auth.data.roles,
});

const actionCreators = {
  fetchCompetitions,
};

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  //   position: 'relative',
  // },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  // title: {
  //   flexGrow: 1,
  // },
  // list: {
  //   width: 250,
  // },
  // fullList: {
  //   width: 'auto',
  // },
  // fab: {
  //   position: 'absolute',
  //   bottom: theme.spacing(6),
  //   right: theme.spacing(6),
  // },
}));

export function Competitions({
  fetchCompetitions,
  competitions,
  total_entries,
  page,
  per_page,
}) {
  React.useEffect(() => {
    Promise.all([fetchCompetitions()]);
  }, []);

  const classes = useStyles();

  const classname = (v) => {
    if (v === 100) {
      return "#4caf50";
    } else if (v < 100 && v > 50) {
      return "#ff9800";
    } else {
      return "#f44336";
    }
  };

  const handleSearch = (e) => {
    fetchCompetitions({ query: e.target.value });
  };

  const handlePageChange = (event, value) => {
    fetchCompetitions({ page: value });
  };

  return (
    <div>
      <Container maxWidth="lg">
        <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
          <Link color="inherit" href="/">
            Home
          </Link>
          <Typography color="textPrimary">Competitions</Typography>
        </Breadcrumbs>

        <Box
          display="flex"
          flexDirection="row-reverse"
          style={{ marginBottom: 24 }}
        >
          <TextField
            id="standard-basic"
            label="Search ..."
            onChange={handleSearch}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">Nation</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Progress</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {competitions &&
                competitions.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell size="small">
                      <Badge
                        badgeContent={
                          <>
                            {row.gender === "men" && (
                              <ManIcon sx={{ fontSize: 14 }} />
                            )}
                            {row.gender === "ladies" && (
                              <WomanIcon sx={{ fontSize: 14 }} />
                            )}
                            {row.gender === "mixed" && (
                              <WcIcon sx={{ fontSize: 14 }} />
                            )}
                          </>
                        }
                        color="primary"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {row.hill ? (
                          <Avatar
                            variant="rounded"
                            src={row.hill.image}
                            width="32"
                            alt=""
                          />
                        ) : (
                          <Avatar
                            width="32"
                            alt=""
                            variant="rounded"
                            src={
                              "https://upload.wikimedia.org/wikipedia/en/thumb/0/07/F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg/1200px-F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg.png"
                            }
                          />
                        )}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {row.hill ? row.hill.location.name : row.remarks}
                    </TableCell>
                    <TableCell align="center">
                      {row.hill && (
                        <img
                          src={flags[row.hill.location.nation.shortname]}
                          width="32"
                          alt=""
                          style={{ border: '1px solid #ccc' }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Chip label={row.category} />
                    </TableCell>
                    <TableCell align="center">{row.date}</TableCell>
                    <TableCell align="center">
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: 18,
                          color: classname(row.progress),
                        }}
                      >
                        {row.progress}%
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      {
                        <Button
                          href={`/competition/${row.id}`}
                          component={Link}
                        >
                          {row.progress === 100 ? "Review" : "Start"}
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Stack spacing={2} style={{ display: 'flex', alignItems: 'center', padding: 24, }}>
            {total_entries && (
              <Pagination
                count={Math.floor(total_entries / per_page)}
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Stack>
        </TableContainer>
      </Container>
    </div>
  );
}

export default connect(mapState, actionCreators)(Competitions);
