import React from "react";

import { makeStyles } from "@mui/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import FlightIcon from "@mui/icons-material/Flight";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { withStyles } from "@mui/styles";


import flags from "../../utils/flags";
import { score } from "../../utils/marks";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  root2: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    height: 220,
    width: 84,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  active: {
    backgroundColor: "rgba(125, 224, 129, 0.3)",
  },
  flag: {
    maxHeight: 32,
  },
  wellDone: {
    color: "#fff",
    backgroundColor: "#4caf50",
    padding: 4,
  },
  notBad: {
    color: "#fff",
    backgroundColor: "#ggg",
    padding: 4,
  },
  inaccurate: {
    color: "#fff",
    backgroundColor: "#f44336",
    padding: 4,
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12)",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const CustomTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#333"
    }
  }
})(TextField);

function Summary(props) {
  const classes = useStyles();
  const { outrun, flight, landing, average, experts_mark } = props;
  const value = 20 + flight + landing + outrun;

  const markClassName = (value, average) => {
    const v = score(value, average);
    if (v === 0) {
      return 'success';
    } else if (v === 1) {
      return 'warning';
    } else {
      return 'error';
    }
  };

  return (
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Alert variant="outlined" severity="info">
          <div class={classes.root2}>
            Judges' average was
            <Chip color={markClassName(value, average)} label={average} />
            {experts_mark.total && (
              <>
                Superusers' average was
                <Chip
                  variant="rounded"
                  color={markClassName(value, average)}
                  label={experts_mark.total}
                />
              </>
            )}
          </div>
        </Alert>

        <Box display="flex" justifyContent="center" style={{ marginTop: 24 }}>
          <CustomTextField
            disabled
            id="outlined-basic"
            label="Your mark"
            variant="outlined"
            value={value.toFixed(1)}
          />
        </Box>

        <Grid container justifyContent="center" spacing={3} style={{ marginTop: 24 }}>
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button disabled>
              <FlightIcon /> {flight.toFixed(1)}
            </Button>
            <Button disabled>
              <FlightLandIcon /> {landing.toFixed(1)}
            </Button>
            <Button disabled>
              <AirportShuttleIcon /> {outrun.toFixed(1)}
            </Button>
          </ButtonGroup>
        </Grid>

        <Grid container justifyContent="center" spacing={2} style={{ marginTop: 24 }}>
          {props.marks.map((mark) => (
            <Grid item>
              <Paper
                className={classes.paper}
                justify="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <div>
                  <img
                    src={flags[mark.member.nation]}
                    alt=""
                    width="48"
                    className={classes.flag}
                  />
                </div>
                {mark.total}
                <strong>{mark.order}</strong>

                <div style={{ display: "flex", width: 64 }}>
                  <List dense style={{ width: "90%" }}>
                    <ListItem disableGutters style={{ color: "#666" }}>
                      <ListItemAvatar style={{ minWidth: "auto" }}>
                        <FlightIcon style={{ fontSize: 13 }} />
                      </ListItemAvatar>
                      <ListItemText
                        style={{ textAlign: "right", fontSize: 11 }}
                      >
                        {(mark.flight ? -mark.flight : 0).toFixed(1)}
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem disableGutters style={{ color: "#666" }}>
                      <ListItemAvatar style={{ minWidth: "auto" }}>
                        <FlightLandIcon style={{ fontSize: 13 }} />
                      </ListItemAvatar>
                      <ListItemText
                        style={{ textAlign: "right", fontSize: 11 }}
                      >
                        {(mark.landing ? -mark.landing : 0).toFixed(1)}
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem disableGutters style={{ color: "#666" }}>
                      <ListItemAvatar style={{ minWidth: "auto" }}>
                        <AirportShuttleIcon style={{ fontSize: 13 }} />
                      </ListItemAvatar>
                      <ListItemText
                        style={{ textAlign: "right", fontSize: 11 }}
                      >
                        {(mark.outrun ? -mark.outrun : 0).toFixed(1)}
                      </ListItemText>
                    </ListItem>
                  </List>
                </div>
              </Paper>
            </Grid>
          ))}
          <Grid item>
            <Paper
              className={`${classes.paper} ${
                experts_mark.total ? classes.active : classes.disabled
              }`}
              justify="center"
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              <div style={{ height: 36 }}>
                FIS <SupervisorAccountIcon />
              </div>
              {experts_mark.total ? experts_mark.total : "-"}
              <strong>SU</strong>

              <div style={{ display: "flex", width: 64 }}>
                <List dense style={{ width: "90%" }}>
                  <ListItem disableGutters style={{ color: "#666" }}>
                    <ListItemAvatar style={{ minWidth: "auto" }}>
                      <FlightIcon style={{ fontSize: 13 }} />
                    </ListItemAvatar>
                    <ListItemText style={{ textAlign: "right", fontSize: 11 }}>
                      {(experts_mark && experts_mark.flight
                        ? -experts_mark.flight
                        : 0
                      ).toFixed(1)}
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem disableGutters style={{ color: "#666" }}>
                    <ListItemAvatar style={{ minWidth: "auto" }}>
                      <FlightLandIcon style={{ fontSize: 13 }} />
                    </ListItemAvatar>
                    <ListItemText style={{ textAlign: "right", fontSize: 11 }}>
                      {(experts_mark && experts_mark.landing
                        ? -experts_mark.landing
                        : 0
                      ).toFixed(1)}
                    </ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem disableGutters style={{ color: "#666" }}>
                    <ListItemAvatar style={{ minWidth: "auto" }}>
                      <AirportShuttleIcon style={{ fontSize: 13 }} />
                    </ListItemAvatar>
                    <ListItemText style={{ textAlign: "right", fontSize: 11 }}>
                      {(experts_mark && experts_mark.outrun
                        ? -experts_mark.outrun
                        : 0
                      ).toFixed(1)}
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </DialogContentText>
    </DialogContent>
  );
}

Summary.propTypes = {
  // onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default Summary;
