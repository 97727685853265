import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  isLoading: false,
  isAuthUser: !!localStorage.getItem("user"),
  data: JSON.parse(localStorage.getItem("user")),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    authSuccess: (state, action) => {
      state.data = action.payload;
      state.isAuthUser = true;
      state.isLoading = false;
    },
    logoutSuccess: (state) => {
      state.data = null;
      state.isAuthUser = false;
      state.token = null;
      state.isLoading = false;
    },
  },
});

// export const auth = (email, password) => {
//     axios.post('/td_users/sign_in.json', {
//         td_user: {
//             email,
//             password,
//         }
//     })
//     .then(function (response) {
//         console.log(response);
//     })
//     .catch(function (error) {
//         console.log(error);
//     });
// };

export const {
  authSuccess,
  startLoading,
  hasError,
  logoutSuccess,
} = authSlice.actions;

export const login = (email, password) => async (dispatch) => {
  dispatch(startLoading());
  return axios
    .post("/td_users/sign_in.json", {
      td_user: {
        email,
        password,
      },
    })
    .then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data));
      return dispatch(authSuccess(response.data));
      // state.isAuthUser = true;
      // state.token = response.data.auth_token;
    })
    .catch((e) => {
      if (e.response.status === 401) {
        return dispatch(hasError(e.response.data.error));
      }
      return dispatch(hasError("Something went wrong"));
    });
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem("user");
  dispatch(logoutSuccess());
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export default authSlice.reducer;
