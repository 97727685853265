import React from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Terminal from "./Terminal";
import Statistics from "./Statistics";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import flags from "../../utils/flags";
import { score, round } from "../../utils/marks";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import FlightIcon from "@mui/icons-material/Flight";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import { styled } from "@mui/material/styles";

import classes from "./Competition.module.css";

import {
  fetchCompetition,
  fetchJumpers,
  fetchRounds,
  saveMark,
  updateMark,
} from "./competitionSlice";

const mapState = (state) => ({
  competition: state.competition.data,
  jumpers: state.competition.jumpers,
  rounds: state.competition.rounds,
  marks: state.competition.marks,
  isLoading: state.competition.isLoading,
});

const actionCreators = {
  fetchCompetition,
  fetchRounds,
  fetchJumpers,
  saveMark,
  updateMark,
};

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = styled(MenuItem)((theme) => ({
  // root: {
  "&:focus": {
    // backgroundColor: theme.palette.primary.main,
    "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
      // color: theme.palette.common.white,
    },
  },
  // },
}));

export const Competition = (props) => {
  const [activeItem, setActiveItem] = React.useState({
    open: false,
    result: null,
  });

  const { id } = useParams();

  const [isStatsOpen, setStatsOpen] = React.useState(false);
  const [summary, setSummary] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [nextAthlete, setNextAthlete] = React.useState(null);
  const [judgesPopoverData, setJudgesPopoverData] = React.useState(null);
  const [detailsPopoverData, setDetailsPopoverData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { competition, jumpers, marks, rounds, isLoading } = props;

  const handleMenuClick = (event) => {
    rounds.length > 1 && setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    props.fetchRounds(id).then((response) => {
      props.fetchCompetition(id, response[0]);
    });
  }, []);

  const handleJudgesPopoverOpen = (e, marks) => {
    e.stopPropagation();
    setJudgesPopoverData({ marks, anchorEl: e.target });
  };

  const handleJudgesPopoverClose = () => {
    setJudgesPopoverData(null);
  };

  const handleDetailsPopoverOpen = (e, detailed_marks) => {
    e.stopPropagation();
    setDetailsPopoverData({ detailed_marks, anchorEl: e.target });
  };

  const handleDetailsPopoverClose = () => {
    setDetailsPopoverData(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleClickOpen = (result) => () => {
    setActiveItem({ open: true, result });
  };

  const handleClose = () => {
    setActiveItem({ open: false, result: null });
    setSummary(false);
  };

  const handleSave = (params) => {
    props.saveMark(id, params).then((result) => {
      setOpenAlert(true);
      setSummary(true);
      setNextAthlete(getNextAthlete());
    });
  };

  const handleUpdate = (params) => {
    props.updateMark(id, params).then((result) => {
      setOpenAlert(true);
      setSummary(true);
      setNextAthlete(getNextAthlete());
    });
  };

  const getNextAthlete = () => {
    const index = competition.results.findIndex(
      (item) => item.id === activeItem.result.id
    );
    const newArr = [
      ...competition.results.slice(index + 1),
      ...competition.results.slice(0, index),
    ];
    return newArr.find((r) => !marks[r.id]);
  };

  const handleNextAthlete = () => {
    setActiveItem({ open: true, result: nextAthlete });
    setSummary(false);
  };

  const handleComplete = () => {
    setActiveItem({ open: false, result: null });
    setSummary(false);
  };

  const handleStats = () => {
    setStatsOpen(true);
  };

  const handleStatsClose = () => {
    setStatsOpen(false);
  };

  // duplicated
  const markClassName = (value, average) => {
    const v = score(value, average);
    if (v === 0) {
      return "success";
    } else if (v === 1) {
      return "warning";
    } else {
      return "error";
    }
  };

  return (
    <div>
      <Container maxWidth="lg">
        {isLoading && (
          <div style={{ marginTop: 36 }}>
            <LinearProgress />
          </div>
        )}

        {competition && jumpers && marks ? (
          <>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
              <Link color="inherit" href="/">
                Dashboard
              </Link>
              <Link color="inherit" href="/competitions">
                Competitions
              </Link>
              <Typography color="textPrimary">
                {competition.hill
                  ? competition.hill.location.name
                  : competition.remarks}{" "}
                {competition.date}
              </Typography>
            </Breadcrumbs>

            <Grid
              container
              spacing={1}
              style={{ marginBottom: 24, marginTop: 24 }}
            >
              <Grid container item sm={6}>
                <Typography variant="h4" color="primary">
                  {competition.hill
                    ? competition.hill.location.name
                    : competition.remarks}{" "}
                  {competition.date}
                </Typography>
              </Grid>
              <Grid container item sm={6}>
                <div
                  style={{
                    marginRight: 0,
                    marginLeft: "auto",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginRight: 16,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: 8, fontSize: 16 }}>
                      Progress
                    </span>
                    <Avatar style={{ fontSize: 13 }}>
                      {Math.floor(
                        (competition.results.filter((el) => marks[el.id])
                          .length /
                          competition.results.length) *
                          100
                      )}
                      %
                    </Avatar>
                  </div>

                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    style={{
                      display: "block",
                      marginTop: 8,
                    }}
                  >
                    <Button variant="outlined" onClick={handleStats}>
                      Statistics
                    </Button>

                    <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleMenuClick}
                    >
                      {round(competition.round)}
                      {rounds.length > 1 && <ArrowDropDownIcon />}
                    </Button>
                  </ButtonGroup>

                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {rounds.map((el) => {
                      return (
                        <StyledMenuItem
                          key={el}
                          onClick={() => props.fetchCompetition(id, el)}
                        >
                          <ListItemText primary={round(el)} />
                        </StyledMenuItem>
                      );
                    })}
                  </StyledMenu>
                </div>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Bib</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Nation</TableCell>
                    <TableCell align="center">Jump</TableCell>
                    <TableCell align="center">Your mark</TableCell>
                    <TableCell align="center">Judges avg.</TableCell>
                    <TableCell align="center">SUs avg.</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {competition &&
                    competition.results &&
                    competition.results.map((row, index) => {
                      const userMark = marks[row.id];
                      return (
                        <TableRow key={`${row.name}-${index}`}>
                          <TableCell align="center">{row.bib}.</TableCell>
                          <TableCell>
                            <Avatar
                              variant="rounded"
                              src={
                                row.fiscodex &&
                                jumpers[row.fiscodex] &&
                                jumpers[row.fiscodex].photo
                              }
                              width="32"
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            <img
                              src={flags[row.nation]}
                              alt={row.nation}
                              width="32"
                              style={{ border: "1px solid #ccc" }}
                            />
                          </TableCell>
                          <TableCell align="center">{row.distance}</TableCell>
                          <TableCell align="center">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {userMark && (
                                <Avatar
                                  variant="rounded"
                                  sx={{ fontSize: 13 }}
                                  onMouseEnter={(e) =>
                                    handleDetailsPopoverOpen(e, {
                                      flight: userMark.flight,
                                      outrun: userMark.outrun,
                                      landing: userMark.landing,
                                    })
                                  }
                                  onMouseLeave={handleDetailsPopoverClose}
                                >
                                  {userMark.total}
                                </Avatar>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            {userMark ? (
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                color={markClassName(
                                  userMark.total,
                                  row.mark_average
                                )}
                                // className={markClassName(
                                //   userMark.total,
                                //   row.mark_average
                                // )}
                                badgeContent={
                                  // <SmallAvatar
                                  // >
                                  Math.abs(
                                    row.mark_average - userMark.total
                                  ).toFixed(1)
                                  // </SmallAvatar>
                                }
                              >
                                <Avatar
                                  variant="rounded"
                                  sx={{ fontSize: 13 }}
                                  onMouseEnter={(e) =>
                                    handleJudgesPopoverOpen(e, row.marks)
                                  }
                                  onMouseLeave={handleJudgesPopoverClose}
                                >
                                  {row.mark_average}
                                </Avatar>
                              </Badge>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {userMark && row.experts_mark.total ? (
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                color={markClassName(
                                  userMark.total,
                                  row.experts_mark.total
                                )}
                                badgeContent={Math.abs(
                                  row.experts_mark.total - userMark.total
                                ).toFixed(1)}
                              >
                                <Avatar
                                  variant="rounded"
                                  sx={{ fontSize: 13 }}
                                  className={classes.mark}
                                  onMouseEnter={(e) =>
                                    handleDetailsPopoverOpen(
                                      e,
                                      row.experts_mark
                                    )
                                  }
                                  onMouseLeave={handleDetailsPopoverClose}
                                >
                                  {row.experts_mark.total}
                                </Avatar>
                              </Badge>
                            ) : (
                              "-"
                            )}
                          </TableCell>

                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={handleClickOpen(row)}
                              // disabled={!!userMark}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </Container>

      {competition && (
        <Terminal
          open={activeItem.open}
          // TODO: refactor to use redux
          result={activeItem.result}
          userMark={activeItem.result && marks[activeItem.result.id]}
          next={nextAthlete}
          index={activeItem.result ? competition.results.findIndex((el) => el.id === activeItem.result.id) : 0}
          jumper={
            activeItem &&
            activeItem.result &&
            jumpers[activeItem.result.fiscodex]
          }
          competition={competition}
          onClose={handleClose}
          onSave={handleSave}
          onUpdate={handleUpdate}
          onNextAthlete={handleNextAthlete}
          onComplete={handleComplete}
          isLoading={props.isLoading}
          summary={summary}
          video_id={competition.video_id}
        />
      )}
      <Statistics
        open={isStatsOpen}
        results={competition && competition.results}
        marks={marks}
        onClose={handleStatsClose}
      />

      <Popover
        style={{ pointerEvents: "none" }}
        id={id}
        open={Boolean(judgesPopoverData && judgesPopoverData.anchorEl)}
        onClose={handleJudgesPopoverClose}
        anchorEl={judgesPopoverData && judgesPopoverData.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 8,
          }}
        >
          {judgesPopoverData &&
            judgesPopoverData.marks.map((el, index) => (
              <Paper
                key={index}
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {el.total}
                <div>
                  <img
                    src={flags[el.member.nation]}
                    alt={el.member.nation}
                    width="12"
                  />
                </div>
              </Paper>
            ))}
        </div>
      </Popover>

      <Popover
        style={{ pointerEvents: "none" }}
        id={id}
        open={Boolean(detailsPopoverData && detailsPopoverData.anchorEl)}
        onClose={handleDetailsPopoverClose}
        anchorEl={detailsPopoverData && detailsPopoverData.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 8,
          }}
        >
          {detailsPopoverData && detailsPopoverData.detailed_marks && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              }}
            >
              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.flight}
                <div>
                  <FlightIcon fontSize="small" color="action" />
                </div>
              </Paper>

              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.landing}
                <div>
                  <FlightLandIcon fontSize="small" color="action" />
                </div>
              </Paper>

              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.outrun}
                <div>
                  <AirportShuttleIcon fontSize="small" color="action" />
                </div>
              </Paper>
            </div>
          )}
        </div>
      </Popover>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        message="Your mark has been saved!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default connect(mapState, actionCreators)(Competition);
