import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

export const marksSlice = createSlice({
  name: "marks",
  initialState: {
    isLoading: false,
    hasError: undefined,
    profile: {},
    marks: { data: [], total_entries: 0, },
    filters: {
      competitions: [],
      nations: [],
      users: [],
      athletes: [],
    },
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    fetchMarksSuccess: (state, action) => {
      state.marks = action.payload;
      state.isLoading = false;
    },
    fetchCompetitionsSuccess: (state, action) => {
      state.filters.competitions = action.payload;
    },
    fetchNationsSuccess: (state, action) => {
      state.filters.nations = action.payload;
    },
    fetchTdUsersSuccess: (state, action) => {
      state.filters.users = action.payload;
    },
    fetchAthletesSuccess: (state, action) => {
      state.filters.athletes = action.payload;
    },
  },
});

export const {
  fetchMarksSuccess,
  fetchCompetitionsSuccess,
  fetchNationsSuccess,
  fetchTdUsersSuccess,
  fetchAthletesSuccess,
  startLoading,
  hasError,
} = marksSlice.actions;

export const fetchNations = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/nations.json`, { headers })
    .then((response) => {
      dispatch(fetchNationsSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchCompetitions = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/competitions.json`, { headers })
    .then((response) => {
      dispatch(fetchCompetitionsSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchTdUsers = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/td_users.json`, { headers })
    .then((response) => {
      dispatch(fetchTdUsersSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchAthletes = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  axios
    .get(`/api/athletes.json`, { headers })
    .then((response) => {
      dispatch(fetchAthletesSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};


export const fetchAllMarks = ({ query, page, filters, sort } = {}) => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get(`/api/marks.json`, { headers, params: { page: page ? page + 1 : undefined, q: {...filters, s: sort} } })
    .then((response) => {
      dispatch(fetchMarksSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};


export default marksSlice.reducer;
