import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    hasError: undefined,
    profile: {},
    latestCompetitions: { competitions: [], }, //  renamed from competitions
    mostActiveUsers: undefined,
    mostOftenJudgedCompetitions: undefined,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    fetchLatestCompetitionsSuccess: (state, action) => {
      state.latestCompetitions = action.payload;
      state.isLoading = false;
    },
    fetchMostActiveUsersSuccess: (state, action) => {
      state.mostActiveUsers = action.payload;
      state.isLoading = false;
    },
    fetchMostOftenJudgedCompetitionsSuccess: (state, action) => {
      state.mostOftenJudgedCompetitions = action.payload;
      state.isLoading = false;
    },
    fetchProfileSuccess: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    fetchRankingSuccess: (state, action) => {
      state.ranking = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  fetchLatestCompetitionsSuccess,
  fetchProfileSuccess,
  fetchRankingSuccess,
  fetchMostActiveUsersSuccess,
  fetchMostOftenJudgedCompetitionsSuccess,
  startLoading,
  hasError,
} = dashboardSlice.actions;

export const fetchLatestCompetitions = ({ query, page } = { page: 1 }) => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get("/api/competitions/latest.json", { params: { query, page }, headers })
    .then((response) => {
      dispatch(fetchLatestCompetitionsSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchUserProfile = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get("/api/td_users/profile.json", { headers })
    .then((response) => {
      dispatch(fetchProfileSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchRanking = (competitionId) => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get(`/api/competitions/${competitionId}/ranking.json`, { headers })
    .then((response) => {
      dispatch(fetchRankingSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchMostActiveUsers = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get(`/api/td_users/most_active.json`, { headers })
    .then((response) => {
      dispatch(fetchMostActiveUsersSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};

export const fetchMostOftenJudgedCompetitions = () => async (dispatch, getState) => {
  const { auth_token, email } = getState().auth.data;
  const headers = {
    "X-TdUser-Token": auth_token,
    "X-TdUser-Email": email,
  };
  dispatch(startLoading());
  axios
    .get(`/api/competitions/most_often_judged.json`, { headers })
    .then((response) => {
      dispatch(fetchMostOftenJudgedCompetitionsSuccess(response.data));
    })
    .catch(function (e) {
      dispatch(hasError(e.message));
    });
};


export default dashboardSlice.reducer;
