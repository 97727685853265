import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import qs from 'qs';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { makeStyles, ThemeProvider } from '@mui/styles';

import { createTheme } from '@mui/material/styles';

import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey('9df9cc06a8d69ccd97383ed47d623739Tz01MTg2NyxFPTE2OTY1MDAzNzQ1MDgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, {arrayFormat: 'brackets'})
}

const theme = createTheme({
  typography: {
    h1: {
      fontSize: '2rem',
      marginTop: 24,
      marginBottom: 24,
    },
    h5: {
      margin: 0,
    }
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#039be5',
      dark: '#006db3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#4caf50',
      light: '#6fbf73',
      dark: '#357a38',
      contrastText: '#000',
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

serviceWorker.unregister();