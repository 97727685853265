import { Pie } from "react-chartjs-2";
import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

function PieChartComponent({ marks }) {
  if (!marks) {
    return <Skeleton variant="circular" width={256} height={256} />;
  }

  const labels = ["0 ≤ Diff < 0,5", "0.5 ≤ Diff < 1", "Diff ≥ 1"];
  const datasets = [
    {
      data: [marks.diff1, marks.diff2, marks.diff3],
      backgroundColor: ["#4caf50", "#ed6c02", "#d32f2f", "#58508d", "#bc5090"],
    },
  ];

  return (
    <Pie
      options={{
        width: "300",
        height: "300",
      }}
      data={{
        labels: labels,
        datasets: datasets,
      }}
    />
  );
}

export default PieChartComponent;
