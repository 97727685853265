export const score = (value, average) => {
  if (Math.abs(average - value) <= 0.5) {
    return 0;
  }

  if (
    (average >= 17 &&
      Math.abs(average - value) > 0.5 &&
      Math.abs(average - value) <= 0.7) ||
    (average < 17 && average >= 14 && Math.abs(average - value) <= 1.0) ||
    (average < 14 && Math.abs(average - value) <= 1.5)
  ) {
    return 1;
  }

  return 2;
};

export const round = (el) => {
  switch (el) {
    case "Q0":
      return "Qualification";
    case "C0":
      return "Round 1";
    case "C1":
      return "Round 2";
    case "C2":
      return "Round 3";
    case "C3":
      return "Round 4";
    default:
      return "-";
  }
};